import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import {
  MANAGE_FIELDS_EV_ID,
  MANAGE_MEMBERS_PAGE_EV_ID,
} from '../../components/MyAccount/manifest';
import { memberAccountBMPath } from '../constants';
import { openGeneralSettingsPanel } from './members-area-api';
import { refreshApp } from './public-api';

export function registerGfppEventListeners(editorSDK: FlowEditorSDK) {
  editorSDK.addEventListener('widgetGfppClicked', ({ detail }) => {
    switch (detail.id) {
      case MANAGE_FIELDS_EV_ID: {
        editorSDK?.editor
          .openDashboardPanel('', {
            url: `${memberAccountBMPath}?referralInfo=my_account_gfpp_cta`,
            closeOtherPanels: false,
          })
          .then(() => refreshApp(editorSDK));
        break;
      }
      case MANAGE_MEMBERS_PAGE_EV_ID: {
        openGeneralSettingsPanel(editorSDK);
      }
    }
  });
}
