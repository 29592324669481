import { GetAppManifestFn, EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

import { Experiment } from '../../types';
import componentJson from './.component.json';

type DePromisify<T> = T extends Promise<infer U> ? U : T;

type AppManifest = DePromisify<ReturnType<GetAppManifestFn>>;

export const MANAGE_FIELDS_EV_ID = 'manage-fields-clicked';

export const MANAGE_MEMBERS_PAGE_EV_ID = 'manage-members-pages-clicked';

export const createAppManifest = (
  t: EditorScriptFlowAPI['translations']['t'],
  experiments: EditorScriptFlowAPI['experiments'],
): AppManifest => {
  return {
    controllersStageData: {
      [componentJson.id]: {
        default: {
          gfpp: {
            desktop: {
              mainAction2: experiments.enabled(Experiment.EnableGeneralSettings)
                ? {
                    actionId: MANAGE_MEMBERS_PAGE_EV_ID,
                    label: t('app.gfpp.manage-member-pages'),
                  }
                : {
                    actionId: MANAGE_FIELDS_EV_ID,
                    label: t('app.settings.main.secondary-cta'),
                  },
              iconButtons: {},
            },
            mobile: {
              iconButtons: {},
            },
          },
        },
      },
    },
  };
};
